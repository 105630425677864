.del-temporary-header,
.del-temporary-footer {
  height: 50px;
  width: 100%;
  background-color: $del-color-light-grey;
  display: flex;
  justify-content: center;
  align-items: center;
}

.del-plp-page-wrapper {
  max-width: 1400px;
  margin: 0 auto;
}

.del-plp-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100%;
}

.js-prod-anchor {
  visibility: hidden;
  position: absolute;
  top: -120px;
}

.del-product-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid transparent;

  &__prices-wrapper {
    @include del-font-book;

    .--list {
      display: flex;
      flex-direction: column;
      gap: 2px;
      list-style: none;
      text-align: right;
    }

    .--mainPrice {
      font-size: REM(18px);
      color: var(--brand-06);
      line-height: 1.35;
    }

    .--info {
      display: flex;
      flex-direction: column;
      gap: 2px;
      padding-bottom: 5px;
    }

    .--strikePrice {
      text-decoration: line-through;
    }

    .--strikePrice,
    .--labelPrice {
      font-size: REM(13px);
      color: var(--brand-07);
    }

    .--labelPrice {
      word-break: break-all;
      padding-bottom: 14px;
    }
  }

  &__bottom-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    flex-shrink: 1;
    background-color: $del-color-white;

    @include bp('del-breakpoint-l') {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
      transform: translateY(100%);
      width: calc(100% + 2px);
      margin: 0 -1px 0 -1px;
      padding: 0 15px 15px 15px;
      border-right: 1px solid $del-color-grey;
      border-bottom: 1px solid $del-color-grey;
      border-left: 1px solid $del-color-grey;
      box-shadow: 2px 2px 4px 0 $del-color-light-grey;
      visibility: hidden;
      opacity: 0;
      transition: $del-transition-general-hover;
    }
  }

  &__image-wrapper {
    position: relative;
    display: flex;
    justify-content: center;

    img {
      display: block;
      height: auto;
      width: auto;
      max-height: 300px;
      max-width: 100%;
    }
  }

  &__image-overlay {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.05);
  }

  &__image {
    width: 100%;
  }

  &__wishlist-icon {
    width: 28px;
    height: 28px;
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
    border: none;
    transition: $del-transition-general-hover;

    &.icon-wishlist {
      opacity: 0.7;
      background-color: transparent;
      border-radius: 100%;

      &:hover {
        opacity: 1;
        background-color: $del-color-white-light-mask;
        transform: scale(1.15);
      }
    }

    &.icon-wishlist-active {
      &:hover {
        opacity: 0.7;
      }
    }
  }

  &__description-wrapper {
    display: flex;
    padding: 25px 0 20px 0;
    flex-direction: column;
  }

  &__top-section,
  &__bottom-section {
    display: flex;
    width: 100%;
    flex-shrink: 0;
  }

  &__top-section {
    position: relative;
    padding-bottom: 13px;

    @include bp('del-breakpoint-max-l', 'max') {
      padding-bottom: 16px;
    }

    @include bp('del-breakpoint-max-s', 'max') {
      padding-bottom: 20px;
    }
  }

  &__bottom-section {
    flex-direction: row;
  }

  &__right-section {
    width: 35%;
    position: relative;
  }

  &__left-section {
    width: 65%;
  }

  &__product-state,
  &__product-stock {
    position: absolute;
    text-transform: uppercase;
    font-size: toRem(13px);
  }

  &__product-state {
    @include del-font-demi;

    left: 0;
    bottom: -4px;
    height: fit-content;
    line-height: 22px;
    max-width: 300px;

    .percentage-discount-span {
      @include del-font-demi;
      color: var(--brand-03);
      padding: 5px;
      background-color: var(--brand-05);
    }

    span {
      @include del-font-demi;
      color: var(--brand-05);
      background-color: var(--support-03--light);
      padding: 5px;
      line-height: 12px;
      margin-bottom: 4px;
      display: inline-block;
    }
  }

  &__product-state-promo {
    display: none;
  }

  &__percentage-discount {
    display: none;
  }

  &__product-stock {
    @include del-font-medium;
    color: $del-color-text-60;
    right: 0;
    top: -20px;
  }

  &__info-wrapper {
    min-height: 70px;
    display: flex;
    flex-direction: column;
  }

  &__category {
    @include del-font-medium;
    color: var(--brand-07);
    text-transform: uppercase;
    font-size: REM(14px);
    padding-bottom: 5px;
    letter-spacing: 1px;
  }

  &__title {
    @include del-font-medium;
    font-size: REM(20px);
    line-height: 1.15;
    color: $del-color-text;
    width: 207px;
    padding-bottom: 20px;

    @include bp('del-breakpoint-max-l', 'max') {
      width: unset;
    }

    @include bp('del-breakpoint-max-s', 'max') {
      width: 207px;
    }
  }

  .del-product-info__energy-label {
    margin-bottom: 25px;

    .fiche {
      font-size: REM(13px);
    }

    .label {
      img {
        width: 66px;
        height: 33px;
      }
    }
  }

  &--grid-view {
    .del-product-info__energy-label {
      .fiche {
        font-size: REM(12px);
      }

      .label {
        img {
          width: 54px;
          height: 26px;

          @include bp('del-breakpoint-s') {
            width: 66px;
            height: 33px;
          }
        }
      }
    }
  }

  &__code {
    @include del-font-medium;
    font-size: REM(14px);
    text-transform: uppercase;
    color: $del-color-text-60;
    padding-bottom: 20px;
    letter-spacing: 1px;
  }

  &__review {
    display: flex;
    align-items: center;
    min-height: 15px;

    i {
      width: 14px;
      height: 14px;
    }
  }

  &__bv-review {
    min-height: 24px;
  }

  &__review-icon {
    width: 14px;
    height: 14px;
  }

  &__review-number {
    color: $del-color-text-60;
    padding: 0 5px;
  }

  &__right-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  &__price-wrapper {
    width: 100%;
    text-align: right;
  }

  &__vat-label {
    font-size: toRem(11px);
    letter-spacing: -0.12px;

    @include bp('del-breakpoint-s') {
      font-size: toRem(12px);
      letter-spacing: normal;
    }
  }

  &__compare {
    display: flex;
    align-items: center;
  }

  &__compare-label {
    padding: 0 5px;
    font-size: REM(13px);
    color: $del-color-text-60;
  }

  &__variants {
    padding-bottom: 10px;
    height: 35px;
    position: relative;
  }

  &__variants-number-top {
    display: none;
  }

  &__variants-number-bottom {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: $del-transition-general-hover;

    @include bp('del-breakpoint-l') {
      opacity: 1;
      visibility: visible;
    }
  }

  &__variant-wrapper {
    display: inline-block;
    text-decoration: none;
    line-height: 100%;
    padding-bottom: 2px;
    border-bottom: 2px solid transparent;
    transition: $del-transition-general-hover;

    &:hover {
      text-decoration: none;
      border-bottom: 2px solid $del-color-black;
    }

    @include bp('del-breakpoint-l') {
      opacity: 0;
      visibility: hidden;
    }
  }

  &__variant-image {
    width: 30px;
  }

  &__divider {
    height: 1px;
    background-color: $del-color-grey;
    transition: $del-transition-general-hover;

    @include bp('del-breakpoint-l') {
      opacity: 0;
    }
  }

  &__key-features {
    list-style-position: outside;
    padding: 20px 15px 20px 30px;

    @include bp('del-breakpoint-l') {
      padding: 0 15px 20px 20px;
    }
  }

  &__key-feature {
    padding: 5px 0;
    font-size: toRem(18px);
    line-height: 115%;

    @include bp('del-breakpoint-l') {
      font-size: toRem(15px);
    }
  }

  &__cta-wrapper {
    display: flex;
    flex-direction: column;

    &--brochureware {
      flex-direction: column;
    }
  }

  &__cta-add-to-cart,
  &__cta-learn-more,
  &__cta-where-to-try {
    margin: 10px 0;

    @include bp('del-breakpoint-l') {
      flex-grow: 1;
      margin: 5px;
    }
  }

  &__cta-where-to-try {
    @include del-font-medium;

    padding: 10px 22px;
    font-size: toRem(14px);
    text-decoration: none;
    text-align: center;
    color: $del-color-text-80;

    @include bp('del-breakpoint-s') {
      padding: 12px 24px;
    }
  }

  // Mobile Grid View
  &--grid-view {
    @include bp('del-breakpoint-max-s', 'max') {
      width: 155px;
      max-width: 155px;

      .del-product-box {
        &__product-state {
          display: none;
        }

        &__percentage-discount {
          @include del-font-demi;
          display: unset;
          position: absolute;
          left: 0;
          bottom: 0;
          height: fit-content;
          line-height: 18px;
          max-width: 300px;

          .percentage-discount-span {
            @include del-font-demi;
            color: var(--brand-03);
            padding: 5px;
            background-color: var(--brand-05);
            font-size: 9px;
          }
        }

        &__product-state-promo {
          @include del-font-demi;
          display: unset;
          line-height: 18px;
          margin-top: 2px;
          margin-bottom: 8px;

          span {
            @include del-font-demi;
            color: var(--brand-05);
            background-color: var(--support-03--light);
            padding: 5px;
            font-size: 9px;
            display: inline-block;
            margin-bottom: 4px;
            line-height: 12px;
            width: max-content;
            text-transform: uppercase;
          }
        }

        &__description-wrapper {
          flex-direction: column;
          padding: 10px 0;
        }

        &__image {
          padding: 25px 0;
        }

        &__wishlist-icon {
          top: 5px;
          right: 5px;
        }

        &__variants-number-top {
          display: block;
          position: absolute;
          top: 8px;
          left: 8px;
          color: $del-color-text-60;
          font-size: REM(12px);
          line-height: 1.13;
        }

        &__left-section,
        &__right-section {
          width: 100%;
        }

        &__right-section {
          align-items: flex-start;
        }

        &__bottom-section {
          flex-direction: column;
        }

        &__title {
          font-size: REM(14px);
          line-height: 1.21;
        }

        &__category,
        &__code {
          font-size: REM(12px);
          line-height: normal;
          letter-spacing: 0.86px;
        }

        &__product-stock {
          position: static;
          top: auto;
          margin-bottom: 5px;
        }

        &__prices-wrapper {
          padding: 10px 0;

          .--list {
            text-align: left;
          }

          .--mainPrice {
            font-size: REM(16px);
          }

          .--strikePrice,
          .--labelPrice {
            font-size: REM(12px);
          }
        }

        &__compare {
          flex-direction: row-reverse;
        }

        &__variants {
          display: none;
        }

        &__divider {
          display: none;
        }

        &__bottom-wrapper {
          display: none;
        }
      }
    }
  }
}

// PLP behaviour
.del-plp-wrapper {
  .del-product-box {
    padding: 15px;

    @include bp('del-breakpoint-s') {
      width: 50%;
    }

    @include bp('del-breakpoint-l') {
      width: 25%;
      position: relative;
      transition: $del-transition-general-hover;

      &.hover,
      &:hover {
        border-top: 1px solid $del-color-grey;
        border-left: 1px solid $del-color-grey;
        border-right: 1px solid $del-color-grey;
        box-shadow: 2px 0 4px 0 $del-color-light-grey;

        .del-product-box__divider {
          opacity: 1;
        }

        .del-product-box__bottom-wrapper {
          opacity: 1;
          visibility: visible;
        }

        .del-product-box__variant-wrapper {
          opacity: 1;
          visibility: visible;
        }

        .del-product-box__variants-number-bottom {
          opacity: 0;
          visibility: hidden;
        }
      }
    }

    &--grid-view {
      @include bp('del-breakpoint-max-s', 'max') {
        width: 155px;
        max-width: 155px;
      }
    }
  }
}

// lite version
.del-product-box.--lite {
  .del-product-box__description-wrapper {
    padding: 0;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .del-product-box__left-section {
    width: 100%;
    text-align: center;
  }

  .del-product-box__info-wrapper {
    min-height: unset;
  }

  .del-product-box__review {
    justify-content: center;
  }

  .del-product-box__right-section {
    width: 100%;
    flex-direction: row;
    margin-top: 20px;
  }

  .del-product-box__prices-wrapper {
    display: flex;
    justify-content: center;
    width: 70%;
    margin: 0 auto;
  }

  .del-product-box__category {
    display: none;
  }
}